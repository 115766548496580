import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { LandingConfiguration } from 'src/app/core/model/landing-configuration.model';
import { Filter } from 'src/app/core/entities/filter.model';
import { LandingElement } from 'src/app/core/model/landing-element.model';
import { LandingElementDeal } from 'src/app/core/model/landing-element-deal.model';
import { GoogleAnalitycsEvent } from 'src/app/core/model/google-analitycs/google-analytics-event.model';
import { GoogleAnalyticsPromotionEvent } from 'src/app/core/model/google-analitycs/google-analytics-promotion-event.model';
import { Vertical } from 'src/app/core/enums/vertical.enum';
import { GOOGLE_ANALYTICS_EVENTS } from 'src/app/core/constant/google-analytics-events.contant';
import { isPlatformBrowser } from '@angular/common';
import { SearchConfig } from '@cocha/ngx-codex';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private isFirst = true;
  private readonly allowedFiltersToAnalytics = ['price', 'month', 'amenities', 'stars', 'foods', 'name', 'baggage', 'stops', 'airlines', 'days', 'baggageQuantity', 'cabinType'];

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        if (!this.isFirst) {
          dataLayer.push({ event: 'pageviewVirtual' });
        } else {
          this.isFirst = false;
        }
      })
  }

  pushImpresions(landing: LandingConfiguration): void {
    landing.elements.forEach((element, index) => {
      if (element.type === 'title') {
        const parsedImpression = this.parseImpresionsSem(element, index);
        if (parsedImpression) {
          this.saveGoogleAnalitycsEvent('Load Impressions', { ecommerce: { impressions: parsedImpression } });
        }
      }
    });
  }

  private parseImpresionsSem(element: LandingElement, index: number): any {
    const impressions: any[] = [];
    element.deals.forEach((deal: LandingElementDeal, idx: number) => {
      const productIndex = 'product' + (1000 * (index + 1) + idx);
      const imp = this.parserDealSem(deal, productIndex);
      impressions.push(imp);
    });
    return impressions;
  }

  private parserDealSem(deal: LandingElementDeal, productIndex: string): any {
    const type = deal.productType || deal.type || '';
    switch (type) {
      case 'vuelo-hotel':
        return this.parseDealSemVueloHotel(deal, productIndex);
      case 'vuelo':
        return this.parseDealSemVuelo(deal, productIndex);
      case 'deeplink-circulo':
        return this.parseDealCaluga(deal, productIndex);
      case 'hotelweb':
        return this.parseDealSemHotelWeb(deal, productIndex);
      default:
        return this.parseDealOtros(deal, productIndex);
    }
  }

  private parseDealSemVueloHotel(deal: LandingElementDeal | any, productIndex: string): any {
    const destination = (deal.from.iata || '') + '|' + (deal.to.iata || '') + '|' + (deal.to.iata || '') + '|' + (deal.from.iata || '');
    const typeCabin = this.getCabinNameFromCode(deal.typeCabin || '-');
    const stars = (deal.items && deal.items.stars) ? deal.items.stars : '-';

    return {
      productIndex,
      id: `P|${destination}`,
      name: `P|-|${destination}`,
      category: 'Vuelo-Hotel',
      brand: '-|-',
      variant: `${typeCabin}|${stars}`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-'
    };
  }

  private parseDealSemVuelo(deal: LandingElementDeal | any, productIndex: string): any {
    let destination = (deal.from.iata || '') + '|' + (deal.to.iata || '');

    if (deal.searchConfig && deal.searchConfig.type && deal.searchConfig.type === 'round-trip') {
      destination += '|' + (deal.to.iata || '') + '|' + (deal.from.iata || '');
    }
    const typeCabin = this.getCabinNameFromCode(deal.typeCabin || '-');

    return {
      productIndex,
      id: `F|${destination}`,
      name: `F|-|${destination}`,
      category: 'Flights',
      brand: '-|-',
      variant: `${typeCabin}|-`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-'
    };
  }

  private parseDealCaluga(deal: LandingElementDeal | any, productIndex: string): any {
    return {
      productIndex,
      id: `C|${deal.title}`,
      name: `C|-|${deal.title}`,
      category: 'Caluga',
      brand: '-|-',
      variant: '-|-',
      dimension12: '-',
      dimension13: '-',
      dimension14: '-',
      dimension15: '-'
    };
  }

  private parseDealSemHotelWeb(deal: LandingElementDeal | any, productIndex: string): any {
    const stars = (deal.items && deal.items.stars) ? deal.items.stars : '-';

    return {
      productIndex,
      id: `H|${deal.destination}`, // + destination, // 'P|SCL|MAD|MAD|SCL',
      name: `H|-|${deal.destination}`, // + destination, // 'P|Hotel Juan Perez|SCL|MAD|MAD|SCL',
      category: 'Hotel',
      brand: '-|-',
      variant: `-|${stars}`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-' // no hay fechas
    };
  }

  private parseDealOtros(deal: LandingElementDeal | any, productIndex: string): any {
    return {
      productIndex,
      id: 'O|-',
      name: 'C|-|-',
      category: 'Otros',
      brand: '-|-',
      variant: '-|-',
      dimension12: '-',
      dimension13: '-',
      dimension14: '-',
      dimension15: '-'
    };
  }

  private getCabinNameFromCode(code: string): string {
    switch (code.toLowerCase()) {
      case 'y':
        return 'Turista';
      case 's':
        return 'Premium Economy';
      case 'c':
        return 'Ejecutiva';
      case 'f':
        return 'Primera';
      default:
        return '-';
    }
  }

  private getLabelsFromVertical(type: string, subType?: string): any {
    const result = { category: 'Buscar' }
    switch (type) {
      case 'flight':
        return { ...result, action: 'Vuelos' };
      case 'flightHotel':
        if (subType === 'holiday') {
          return { ...result, action: 'Buscador de vacaciones' };
        } else {
          return { ...result, action: 'Paquetes' };
        }
      case 'hotel':
        return { ...result, action: 'Hoteles' };
      case 'tripAssistance':
        return { ...result, action: 'Asistencia al viajero' };
      default:
        break;
    }
  }

  private getVerticalFromSearchConfig(search: SearchConfig): string {
    switch (search.vertical) {
      case 'flight':
        return 'V';
      case 'hotel':
        return 'H';
      case 'flightHotel':
        return 'P';
      case 'tripAssistance':
        return 'A';
      default:
        return '';
    }
  }

  private getContriesFromSearchConfig(search: SearchConfig): string {
    let { origin, destination } = this.getOriginAndDestinationProperty(search, 'title', 'subtitle');
    origin = this.addCountryToStaticOriginDestination(origin);
    destination = this.addCountryToStaticOriginDestination(destination);
    switch (search.searchType) {
      case 'roundtrip':
        return `${origin}_${destination}-${destination}_${origin}`;
      case 'multitrip':
        return search.sections?.map((s) => {
          let { origin, destination } = this.getOriginAndDestinationProperty(s, 'title', 'subtitle');
          origin = this.addCountryToStaticOriginDestination(origin);
          destination = this.addCountryToStaticOriginDestination(destination);
          return `${origin}_${destination}`;
        }).join('-') || '';
      case 'oneway':
        return `${origin}_${destination}`;
      default: //SE USA PARA HOTELES        
        if (search.vertical === 'flightHotel') {
          return `${origin}_${destination}`;
        }
        else if (search.vertical === 'hotel') {
          return `${destination}|${search.destination?.subtitle}`;
        }
        return destination;
    }
  }

  private getContriesForTripAssistance(search: SearchConfig) {
    const origin = search.origin?.title;
    const destination = search.destination?.title;
    return `${origin}_${destination}`;
  }

  private getTripAssistancePaxTotal(search: SearchConfig) {
    return search.pax.age_0_21 + search.pax.age_22_70 + search.pax.age_71_85 + search.pax.age_86_more;
  }

  private addCountryToStaticOriginDestination(place: string) {
    switch (place) {
      case 'Santiago':
      case 'Antofagasta':
      case 'Concepción':
        return `${place}, Chile`;
      case 'Cancún':
        return `${place}, México`;
      case 'Punta Cana':
        return `${place}, R. Dominicana`;
      case 'Río de Janeiro':
        return `${place}, Brasil`;
      case 'San Andrés':
        return `${place}, Colombia`;
      case 'Miami':
        return `${place}, Estados Unidos`;
      default:
        return place;
    }
  }

  private getDatesFromSearchConfig(search: SearchConfig): string {
    const origin = (search.start || '').replace(/-/gi, '');
    const destination = (search.end || '').replace(/-/gi, '');
    switch (search.searchType) {
      case 'roundtrip':
        return `${origin}-${destination}`;
      case 'multitrip':
        return search.sections?.map((s) => (s.start || '').replace(/-/gi, '')).join('-') || '';
      case 'oneway':
      default: //TAMBIEN SE USA PARA HOTELES  
        if (search.vertical === 'hotel' || search.vertical === 'flightHotel') {
          return `${origin}-${destination}`;
        }
        if (search.vertical === 'tripAssistance') {
          return `${origin}-${destination}`;
        }
        return origin;
    }
  }

  private getIatasForFlights(search: SearchConfig): string {
    const { origin, destination } = this.getOriginAndDestinationProperty(search, 'iata');
    switch (search.searchType) {
      case 'oneway':
        return `${origin}_${destination}`;
      case 'multitrip':
        return search.sections?.map((s) => {
          const { origin, destination } = this.getOriginAndDestinationProperty(s, 'iata');
          return `${origin}_${destination}`;
        }).join('-') || '';
      case 'roundtrip':
      default:
        return `${origin}_${destination}-${destination}_${origin}`;
    }
  }

  private getPaxInformationFromSearchConfig(search: SearchConfig): string {
    const infantAge = 2;
    switch (search.vertical) {
      case 'flight':
        const adults = `${search.pax.adults}`
        const children = search.pax.babies.filter((c: number) => c >= infantAge).length || 0;
        const infants = search.pax.babies.filter((c: number) => c < infantAge).length || 0;
        return `${adults}-${children}-${infants}`;
      case 'hotel':
      case 'flightHotel':
        return search.pax?.map((s: any) => {
          const adults = `${s.adults}`
          const children = s.children.filter((c: number) => c > infantAge).length || 0;
          const infants = s.children.filter((c: number) => c <= infantAge).length || 0;
          return `${adults}-${children}-${infants}`;
        }).join('_') || '';
      default:
        return '';
    }
  }

  // TODO SectionTrip for any
  private getOriginAndDestinationProperty(search: SearchConfig | any, property: string, subProperty: string = ''): any {
    const originSource: any = search.origin || {};
    const destinationSource: any = search.destination || {};
    const origin = (originSource[property] ? originSource[property] : originSource[subProperty]) || '';
    const destination = destinationSource[property] || '';
    return { origin, destination };
  }

  private getFlightTypeFromSearchConfig(search: SearchConfig): string {
    return search.searchType?.substring(0, 1).toLocaleUpperCase() || '';
  }

  private getProductCode(icon: string): string {
    if (!icon) {
      return '';
    }
    if (icon.includes('package')) {
      return 'P';
    }
    if (icon.includes('going')) {
      return 'V';
    }
    if (icon.includes('hotel')) {
      return 'H';
    }
    if (icon.includes('universal')) {
      return 'A';
    }
    return '';
  }

  sendGoogleAnalitycsEvent({ category, label, action = undefined, nonInteraction = false }: any) {
    const event: GoogleAnalitycsEvent = {
      event_category: category,
      event_action: action,
      event_label: label.toString(),
      event_nonInteraction: nonInteraction
    };
    this.saveGoogleAnalitycsEvent('gaEvent', event);
  }

  // TODO SearchResult for any
  sendAnalitycsFromSearchBox(search: any, type: string) {
    try {
      const searchConfig = search.data;
      const { category, action } = this.getLabelsFromVertical(type, search.data.searchType);
      let label = `${this.getVerticalFromSearchConfig(searchConfig)}`;

      switch (type) {
        case 'flight':
          label = `${label}|${this.getIatasForFlights(searchConfig)}|${this.getContriesFromSearchConfig(searchConfig)}|${this.getDatesFromSearchConfig(searchConfig)}|${this.getPaxInformationFromSearchConfig(searchConfig)}|${this.getFlightTypeFromSearchConfig(searchConfig)}`
          break;
        case 'hotel':
          label = `${label}|${this.getContriesFromSearchConfig(searchConfig)}|${this.getDatesFromSearchConfig(searchConfig)}|${this.getPaxInformationFromSearchConfig(searchConfig)}`;
          break;
        case 'flightHotel':
          if (search.data.searchType === 'holiday') {
            const adultCount = search.data.rooms?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.adults,
              0
            );
            const childrenCount = search.data.rooms?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.children.length,
              0
            );
            const airports = search.data.airports?.map(airport => airport.value);
            // TODO IItem for any
            let concatenatedDestinations: any[] = []
            concatenatedDestinations = concatenatedDestinations.concat(search.data.destinations!.g1s!);
            concatenatedDestinations = concatenatedDestinations.concat(search.data.destinations!.g2s!);
            concatenatedDestinations = concatenatedDestinations.concat(search.data.destinations!.g3s!);
            const destinations = concatenatedDestinations.map(destination => destination.value);
            const startDate = search.data.departureStartDate?.replace(/-/g, '');
            const endDate = search.data.departureEndDate?.replace(/-/g, '');
            label = `P3|${destinations?.join()}_${airports?.map(airport => `${airport}`).join('-')}|${startDate}-${endDate}|${search.data.durations![0]}|${search.data.rooms?.length}|${adultCount}-${childrenCount}|null`
          } else {
            label = `${label}|${this.getIatasForFlights(searchConfig)}|${this.getContriesFromSearchConfig(searchConfig)}|${this.getDatesFromSearchConfig(searchConfig)}|${this.getPaxInformationFromSearchConfig(searchConfig)}`;
          }
          break;
        case 'tripAssistance':
          const category = search.searchURL.split("&").find(params => params.includes("categoria"))?.split("=")[1];
          const ages = search.searchURL.split("&").find(params => params.includes("edad"))?.split("=")[1];
          label = `${label}|${this.getContriesForTripAssistance(searchConfig)}|${this.getDatesFromSearchConfig(searchConfig)}|${this.getTripAssistancePaxTotal(searchConfig)}|${category}|${ages}`
          break;
      }

      this.sendGoogleAnalitycsEvent({ category, label, action });

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  sendPromotionEvent(deal: any, isFromInteraction: boolean = false): void {
    const promotion: GoogleAnalyticsPromotionEvent = {
      event: isFromInteraction ? 'eecPromotionClic' : 'eecPromotionImpression',
      promotion: {
        codigoProducto: this.getProductCode(deal?.icon),
        imagenCaluga: deal?.image || undefined,
        tipoCaluga: deal?.type || undefined,
        tituloCaluga: deal?.title || undefined
      }
    };
    this.saveGoogleAnalitycsEvent(promotion.event!, promotion);
  }


  private saveGoogleAnalitycsEvent(eventName: string, eventData: any): void {
    if (isPlatformBrowser(this.platformId)) {
      if (['eecPromotionImpression', 'eecPromotionClic'].includes(eventData.event)) {
        dataLayer.push(eventData);
      } else {
        const event: GoogleAnalitycsEvent = {
          event: eventData.event || 'gaEvent',
          event_category: eventData.event_category,
          event_action: eventData.event_action,
          event_label: eventData.event_label,
          event_value: eventData.event_value || undefined,
          event_nonInteraction: eventData.event_nonInteraction || false,
        };
        dataLayer.push(event);
      }
    }
  }

  sendSeoSemToGoogleAnalytics(category: string, label: string, action: string, filterName?: string, filterData?: any, nonInteraction?: boolean) {
    if (this.allowedFiltersToAnalytics.includes(filterName!)) {
      switch (filterName) {
        case 'price':
          label = `prices:${filterData?.options[0]}`;
          break;
        case 'baggage':
          label = `baggage:${filterData?.options[0]}`;
          break;
        case 'month':
          label = `Se cargaron solo ${filterData?.options.length} meses`;
          break;
        case 'stops':
          label = `connections:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'days':
          label = `Cantidad de días:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'baggageQuantity':
          label = `Equipaje:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'cabinType':
          label = `Cabina:${filterData?.options[0]}`;
          break;
        default:
          const filterString = filterData?.options.map(f => `${filterName}:${f}`);
          label = filterString?.join('|') || '';
          break;
      }
    }
    this.sendGoogleAnalitycsEvent({ category, label, action, nonInteraction });
  }

  getCategoryFromVertical(vertical?: string): string {
    switch (vertical) {
      case Vertical.FLIGHTS:
        return GOOGLE_ANALYTICS_EVENTS.category.flightsToDestination;
      case Vertical.PACKAGES:
        return GOOGLE_ANALYTICS_EVENTS.category.packagesToDestination;
      case Vertical.HOTELS:
        return GOOGLE_ANALYTICS_EVENTS.category.hotelsToDestination;
      default:
        return '';
    }
  }
}
